import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import Grid from '@/components/Grid'
import elementItemHelper from '@/helpers/elementItemHelper'
import { FONT_16, FONT_22_EXTRA_BOLD, FONT_30_EXTRA_BOLD } from '@/styles/fonts'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { ContentHeadingItem, ContentTextItem } from '@/types/Content'
import TransitionHandler from '@/components/TransitionHandler'

const Partial = {
  Heading({ heading }: { heading?: ContentHeadingItem[] }) {
    if (!heading) return null
    return (
      <>
        {heading.map(({ show, value, level }, index) => (
          <TransitionHandler key={`Grid1TitleWithText-heading-${index}`}>
            <DisplayConfigWrapper show={show}>
              <Styled.Title as={`h${level}`} level={level}>
                {value}
              </Styled.Title>
            </DisplayConfigWrapper>
          </TransitionHandler>
        ))}
      </>
    )
  },
  Text({ text }: { text: ContentTextItem[] }) {
    if (!text) return
    return (
      <>
        {text?.map(({ value, show }, index) => (
          <TransitionHandler key={`Grid1TitleWithText-text-${index}`}>
            <DisplayConfigWrapper show={show}>
              <Styled.Text>{value}</Styled.Text>
            </DisplayConfigWrapper>
          </TransitionHandler>
        ))}
      </>
    )
  },
}

function Grid1TitleWithText({ item }): ReactElement {
  const { columns } = item
  const [{ items }] = columns ?? [{}]
  const { heading, text } = elementItemHelper(items)

  return (
    <Grid>
      <Styled.Wrap>
        <Partial.Heading heading={heading} />
        <Partial.Text text={text} />
      </Styled.Wrap>
    </Grid>
  )
}

const Styled = {
  Wrap: styled.div`
    text-align: left;
    padding: 0 6px;
    word-break: keep-all;
    word-wrap: break-word;
  `,
  Title: styled.h1<{ level?: number }>`
    ${FONT_30_EXTRA_BOLD};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_22_EXTRA_BOLD};
    }
  `,
  Text: styled.p`
    ${FONT_16};
    margin-top: 24px;
    color: ${STYLE_COLOR.BLACK03};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      margin-top: 12px;
    }
  `,
}

export default Grid1TitleWithText
