import React from 'react'
import { ContentSpacingRow } from '@/types/Content'
import styled, { css } from 'styled-components'
import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import { STYLE_BREAKPOINT } from '@/styles/variables'

interface Grid0SpacingProps {
  item: ContentSpacingRow
}

const REGEXP_TYPE_ENUM = /^_(\d+)_(\d+)$/

function Grid0Spacing({ item }: Grid0SpacingProps) {
  return (
    <DisplayConfigWrapper show={item.show}>
      <Styled.Wrap {...item} />
    </DisplayConfigWrapper>
  )
}

const Styled = {
  Wrap: styled.s<ContentSpacingRow>`
    position: relative;
    display: block;
    ${({ height }) => {
      if (REGEXP_TYPE_ENUM.test(height as string)) {
        const [, pc, mobile] = (height as string)?.match(REGEXP_TYPE_ENUM)
        return css`
          height: ${pc}px;
          @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
            height: ${mobile}px;
          }
        `
      }
      return css`
        height: ${height}px;
      `
    }};
  `,
}

export default Grid0Spacing
