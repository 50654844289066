import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { Grid3CardLinkListRow } from '@/types/Content'
import styled, { css } from 'styled-components'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import elementItemHelper from '@/helpers/elementItemHelper'
import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import {
  FONT_12_EXTRA_BOLD,
  FONT_16,
  FONT_18_EXTRA_BOLD,
  FONT_22_EXTRA_BOLD,
  FONT_30_EXTRA_BOLD,
  STYLE_KEEP_WORD,
} from '@/styles/fonts'
import { STYLE_INIT_BUTTON } from '@/styles/buttons'
import {
  BackgroundPathType,
  STYLE_MULTI_BACKGROUND,
  STYLE_SAFE_BACKGROUND_IMAGE,
} from '@/styles/presets'
import { IconTypeFile } from '@/components/Icon'
import Grid from '@/components/Grid'
import Link from 'next/link'

interface Grid3CardLinkListRequiredProps {
  item: Grid3CardLinkListRow
}

type Grid3CardLinkListProps = Grid3CardLinkListRequiredProps

interface Grid3CardLinkListStyledProps {
  isLastColumn: boolean
}

interface HistoryStyleProps {
  fold: boolean
}

const Partial = {
  Image({ image }) {
    if (!image) {
      return null
    }
    return image?.map(({ src, alt, show }, index) => (
      <DisplayConfigWrapper
        show={show}
        key={`Grid3CardLinkList-image-${index}`}
      >
        <Styled.ImageWrap>
          <Styled.Image src={src} alt={alt || ''} />
        </Styled.ImageWrap>
      </DisplayConfigWrapper>
    ))
  },
  Title({ title }) {
    if (!title) {
      return null
    }
    return title?.map(({ value, show, as }, index) => (
      <DisplayConfigWrapper
        show={show}
        key={`Grid3CardLinkList-title-${index}`}
      >
        <Styled.Title as={as}>{value}</Styled.Title>
      </DisplayConfigWrapper>
    ))
  },
  Text({ text }) {
    if (!text) {
      return null
    }
    return text?.map(({ value, show }, index) => (
      <DisplayConfigWrapper show={show} key={`Grid3CardLinkList-text-${index}`}>
        <Styled.Text>{value}</Styled.Text>
      </DisplayConfigWrapper>
    ))
  },
  Category({ category }) {
    if (!category) return null

    return category?.map(({ label }, index) => (
      <Styled.Category key={`Grid3CardLinkList-category-${index}`}>
        {label}
      </Styled.Category>
    ))
  },
}

function Grid3CardLinkList({
  item,
}: PropsWithChildren<Grid3CardLinkListProps>): ReactElement {
  const { columns, type, collapse } = item
  const columnsLength = columns.length
  const categoryCount = 9

  const renderColumnElements = useCallback(
    ({ items }, columnIndex) => {
      const { image, title, text, category, link } = elementItemHelper(items)
      const isLastColumn = columnIndex === columnsLength - 1
      const key = `Grid3CardLinkList-${columnIndex}`

      const linkhref = link?.find((item) => item.href).href

      return (
        <Link key={key} href={linkhref} passHref>
          <Styled.CardWrap isLastColumn={isLastColumn}>
            <Partial.Image image={image} />
            <Styled.CardBody>
              <Partial.Title title={title} />
              <Partial.Text text={text} />
              <Partial.Category category={category} />
            </Styled.CardBody>
          </Styled.CardWrap>
        </Link>
      )
    },
    [columnsLength]
  )

  const [pageSize, setPageSize] = useState(categoryCount)
  const [fold, setFold] = useState(true)

  const getList = useMemo(() => {
    return collapse ? columns?.flat().slice(0, pageSize) : columns?.flat()
  }, [collapse, columns, pageSize])

  const onClickMore = useCallback(() => {
    if (pageSize >= columnsLength - 6) {
      setFold(false)
    }

    if (pageSize < columnsLength) {
      setPageSize((pageSize) => pageSize + 6)
      return
    }

    setPageSize(categoryCount)
    setFold(true)
  }, [columnsLength, pageSize])

  return (
    <>
      <Styled.Grid gridType={type}>
        {getList.map(renderColumnElements)}
      </Styled.Grid>
      {collapse && columnsLength > categoryCount && (
        <Styled.History fold={fold}>
          <Styled.BtnMore fold={fold} onClick={onClickMore}>
            더보기
          </Styled.BtnMore>
        </Styled.History>
      )}
    </>
  )
}

const Styled = {
  Grid: styled(Grid)`
    > div > div {
      display: flex;
      justify-content: start;
      flex-wrap: wrap;

      > div {
        width: 100%;
        margin-bottom: 52px;

        &:nth-last-child(1) {
          margin-bottom: 0;
        }

        @media (min-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH_UPPER}) {
          width: 33.3%;
          margin-bottom: 120px;
          box-sizing: border-box;

          &:nth-last-child(-n + 3) {
            margin-bottom: 0;
          }
        }
      }
    }
  `,

  CardWrap: styled.a<Grid3CardLinkListStyledProps>`
    display: block;
    text-decoration: none;
    color: inherit;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-bottom: ${({ isLastColumn }) => !isLastColumn && 0}px;
    }
  `,
  ImageWrap: styled.div`
    position: relative;
    width: 100%;
    padding-top: 57%;
  `,
  Image: styled.img`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  `,
  CardBody: styled.div`
    padding-top: 50px;
    word-break: keep-all;
    word-wrap: break-word;
    width: 90%;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 24px;
      width: 100%;
    }
  `,
  Title: styled.div`
    ${FONT_22_EXTRA_BOLD};
    white-space: pre-wrap;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_18_EXTRA_BOLD};
    }
  `,
  Text: styled.p`
    padding-top: 8px;
    ${FONT_16};
    color: ${STYLE_COLOR.BLACK02};

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 12px;
    }
  `,
  Label: styled.span`
    ${STYLE_KEEP_WORD};
  `,
  Category: styled.div`
    padding: 4px 8px;
    background: #f5f6f7;
    border-radius: 4px;
    margin-top: 16px;
    display: inline-block;
    ${FONT_12_EXTRA_BOLD};
    color: ${STYLE_COLOR.BLACK04};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding: 2px 6px;
      margin-top: 8px;
    }
  `,
  HistoryTitle: styled.strong`
    display: block;
    margin-bottom: 122px;
    ${FONT_30_EXTRA_BOLD};
    text-align: center;
    white-space: pre-line;

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding: 0 6.666%;
      margin-bottom: 40px;
      ${FONT_22_EXTRA_BOLD};
      text-align: left;
    }
  `,
  History: styled.div<HistoryStyleProps>`
    display: table;
    margin: auto;

    ${({ fold }) => fold && css``}

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      display: block;
      padding: 0 6.666%;
    }
  `,
  BtnMore: styled.button<HistoryStyleProps>`
    ${STYLE_INIT_BUTTON};
    ${FONT_16};
    display: block;
    margin: 80px auto 0;
    text-align: center;

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      width: 100%;
      padding: 10px 0;
      margin: 30px auto -10px;
    }

    &:after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin-left: 8px;
      transition: margin-left 0.2s ease-out;

      ${STYLE_SAFE_BACKGROUND_IMAGE(
        IconTypeFile.IC_16_FOLD,
        16,
        16,
        BackgroundPathType.ICONS
      )};
    }

    ${({ fold }) =>
      !fold &&
      css`
        &:after {
          ${STYLE_MULTI_BACKGROUND(
            IconTypeFile.IC_16_UNFOLD,
            BackgroundPathType.ICONS
          )}
        }
      `}
  `,
}

export default Grid3CardLinkList
