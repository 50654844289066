import React from 'react'
import styled, { css } from 'styled-components'
import Grid from '@/components/Grid'
import {
  ContentButtonItem,
  ContentImageItem,
  ContentRow,
  ContentTextItem,
} from '@/types/Content'
import elementItemHelper from '@/helpers/elementItemHelper'
import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import {
  FONT_16,
  FONT_22,
  FONT_18_EXTRA_BOLD,
  FONT_22_EXTRA_BOLD,
  FONT_30_EXTRA_BOLD,
  FONT_50_EXTRA_BOLD,
  STYLE_FONT_FAMILY,
  STYLE_KEEP_WORD,
} from '@/styles/fonts'
import { STYLE_ARROW_BUTTON_BLACK } from '@/styles/buttons'
import TransitionHandler from '@/components/TransitionHandler'
import { EffectType } from '@/enums/Content'
import AutoLinkText from '@/components/AutoLinkText'

interface Grid1CenterAlignedTextProps {
  item: ContentRow
}

const Partial = {
  Image({ image }: { image: ContentImageItem[] }) {
    if (!image) return null
    return (
      <>
        {image?.map(({ src, alt, show, width, height }, index) => (
          <TransitionHandler
            effect={EffectType.ZOOM}
            key={`Grid1CenterAlignedText-image-${index}`}
            speed={0.6}
          >
            <DisplayConfigWrapper show={show}>
              <Styled.Image
                src={src}
                alt={alt || ''}
                width={width}
                height={height}
              />
            </DisplayConfigWrapper>
          </TransitionHandler>
        ))}
      </>
    )
  },
  Title({ title }: { title: ContentTextItem[] }) {
    if (!title) return null
    return (
      <>
        {title?.map(({ value, show, styleType = 'type1', as }, index) => (
          <TransitionHandler key={`Grid1CenterAlignedText-title-${index}`}>
            <DisplayConfigWrapper show={show}>
              <Styled.Title as={as} styleType={styleType}>
                {value}
              </Styled.Title>
            </DisplayConfigWrapper>
          </TransitionHandler>
        ))}
      </>
    )
  },
  Text({ text }: { text: ContentTextItem[] }) {
    if (!text) return null
    return (
      <>
        {text?.map(({ value, show, styleType = 'type1' }, index) => (
          <TransitionHandler key={`Grid1CenterAlignedText-text-${index}`}>
            <DisplayConfigWrapper show={show}>
              <Styled.Text styleType={styleType}>{value}</Styled.Text>
            </DisplayConfigWrapper>
          </TransitionHandler>
        ))}
      </>
    )
  },
  Button({ button }: { button: ContentButtonItem[] }) {
    if (!button) return null
    return (
      <>
        {button?.map((item, index) => (
          <TransitionHandler key={`Grid1CenterAlignedText-button-${index}`}>
            <DisplayConfigWrapper show={item?.show}>
              <Styled.Button {...item}>{item?.label}</Styled.Button>
            </DisplayConfigWrapper>
          </TransitionHandler>
        ))}
      </>
    )
  },
}

function Grid1CenterAlignedText({ item }: Grid1CenterAlignedTextProps) {
  const { columns, type } = item
  const [{ items }] = columns ?? [{}]
  const { title, text, button, image } = elementItemHelper(items)

  return (
    <Grid gridType={type}>
      <Styled.Wrap>
        <Partial.Image image={image} />
        <Partial.Title title={title} />
        <Partial.Text text={text} />
        <Partial.Button button={button} />
      </Styled.Wrap>
    </Grid>
  )
}

const Styled = {
  Wrap: styled.div`
    font-family: ${STYLE_FONT_FAMILY.KAKAO_BIG};
    text-align: center;
  `,
  Image: styled.img`
    padding-bottom: 30px;
  `,
  Title: styled.div<{ styleType?: string }>`
    ${STYLE_KEEP_WORD};
    white-space: pre-wrap;
    ${({ styleType }) =>
      styleType === 'type1' &&
      css`
        ${FONT_30_EXTRA_BOLD};
      `}
    ${({ styleType }) =>
      styleType === 'type2' &&
      css`
        ${FONT_50_EXTRA_BOLD};
      `}
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_22_EXTRA_BOLD};
    }
  `,
  Text: styled.p<{ styleType?: string }>`
    ${STYLE_KEEP_WORD};
    margin-top: 40px;
    white-space: pre-wrap;
    ${({ styleType }) =>
      styleType === 'type1' &&
      css`
        ${FONT_22_EXTRA_BOLD};
      `}
    ${({ styleType }) =>
      styleType === 'type2' &&
      css`
        ${FONT_22};
        color: ${STYLE_COLOR.BLACK02};
      `}

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding: 0 5%;
      margin-top: 16px;
      ${({ styleType }) =>
        styleType === 'type1' &&
        css`
          ${FONT_18_EXTRA_BOLD};
        `}
      ${({ styleType }) =>
        styleType === 'type2' &&
        css`
          ${FONT_16};
          color: ${STYLE_COLOR.BLACK02};
        `}
    }
  `,
  Button: styled(AutoLinkText)`
    ${STYLE_ARROW_BUTTON_BLACK};
    padding-top: 30px;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 12px;
    }
  `,
}

export default Grid1CenterAlignedText
